import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"

export default (props) => {

	const data = useStaticQuery(graphql`
	query {
		allMdx(
			sort: { order: ASC, fields: [frontmatter___juni] }
			filter: { frontmatter: { syurui: { eq: "kiji" } } }
		) {
			edges {
				node {
					id
					frontmatter {
						path
						date
						title
						description
						syurui
						juni
						key
					}
				}
			}
		}
	}
`)

	const { edges: posts } = data.allMdx


	return (
	<>
		<hr/>

			{posts.map(({ node: post }) => {

				if (post.frontmatter.key == props.kagi) {
					return
				}

				return (

					<p className='kiji_link' key={post.id}>

						<Link to={post.frontmatter.path}>
							<h4 className='sikaku'>{post.frontmatter.title}</h4>
						</Link>

						<p>{post.frontmatter.description}</p>

					</p>
				)
			})}

	</>
	)
}
