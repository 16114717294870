import React from "react"
import { Link } from "gatsby"
import RinenHe from '@components/md-components/rinen-he.md'

import Kiji from "@components/kiji-link"

export default (props) => {


	return (
		<>
			<div class={'sayuu '+props.tuika_class}>

				<hr />

				{/* md本文 */}
				<RinenHe/>

				<Kiji kagi='rinen' tuika_class='t-bold'/>
			</div>
		</>

	)
}