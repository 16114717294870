import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Youtube from "@components/youtube";
import KijiLink from "@components/kiji-link-only";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`僕は、基本的に`}<strong parentName="p">{`インサート１つだけ`}</strong>{`でMIXをしています。それも、DAW付属の「オマケみたいなチャンネルストリップ」だけです。`}</p>
    <p>{`MIXにかかる時間は最短４時間くらい、`}<br parentName="p"></br>{`
`}{`オーディオIFは使わないこともあるし、`}<br parentName="p"></br>{`
`}{`最悪iPhoneのイヤホンだけで`}<br parentName="p"></br>{`
`}{`MIXすることすらあります。`}</p>
    <p>{`実際のMIX画面を見てもらえば、様子がわかると思います↓`}<br parentName="p"></br>{`
`}{`（１分もありません）`}</p>
    <Youtube bangou='9k6U4MvLgZ4' tuika_class='' mdxType="Youtube" />
    <p>{`「ほんまかいな」ですよね。`}<br parentName="p"></br>{`
`}{`ほんまかいなでしょうが、まずは`}<br parentName="p"></br>{`
`}{`こういう世界もあるということを`}<br parentName="p"></br>{`
`}{`知っておいて欲しいのです。`}<br parentName="p"></br>{`
`}{`（詳しい説明は、`}<KijiLink kagi='mix' tuika_class='' mdxType="KijiLink">{`このページ`}</KijiLink>{`に書いています）`}</p>
    <p>{`「いや、お前には才能があったんだろ？」`}<br parentName="p"></br>{`
`}{`とも言われます。しかし、`}<br parentName="p"></br>{`
`}{`僕は`}<strong parentName="p">{`「これは誰でもできる」と確信しています`}</strong>{`。`}<br parentName="p"></br>{`
`}{`（実際に、僕が教えた人もできるようになりました。さっきのページに、教えた人たちの音源も載せています）`}</p>
    <p>{`むしろ、僕は才能があったわけでも`}<br parentName="p"></br>{`
`}{`初めからうまくいったわけでもなく、`}<br parentName="p"></br>{`
`}{`MIXに関しては`}<br parentName="p"></br>{`
`}<strong parentName="p">{`誰よりも苦しんで、誰よりも失敗した`}</strong><br parentName="p"></br>{`
`}{`自信があります。`}</p>
    <p>{`作曲に憧れた１５歳、`}<br parentName="p"></br>{`
`}{`就職してしまった２０代、`}<br parentName="p"></br>{`
`}{`音楽で生きる決意をして`}<br parentName="p"></br>{`
`}{`仕事を辞めた２８歳。`}</p>
    <p>{`そこからは、正直地獄でした。僕は、MIXができなかったせいで、その後`}<strong parentName="p">{`８年間を棒に振りました`}</strong>{`。`}<br parentName="p"></br>{`
`}{`自分の音が安っぽい。`}<br parentName="p"></br>{`
`}{`でも、いくら勉強してもわからない。`}<br parentName="p"></br>{`
`}{`なんとかしたくて、機材に合計６００万円もつぎ込みました。しかし、`}<strong parentName="p">{`それでもMIXはできるようにはなりませんでした`}</strong>{`。`}</p>
    <p>{`仕事もなく、お金もなく、`}<br parentName="p"></br>{`
`}{`毎日家にこもって作業をし、`}<br parentName="p"></br>{`
`}{`不安でおかしくなりそうでした。`}<br parentName="p"></br>{`
`}{`思い出すだけでもしんどいです。`}</p>
    <p>{`でも、そんな僕でも、`}<br parentName="p"></br>{`
`}{`今では驚異的な速度で`}<br parentName="p"></br>{`
`}{`MIXができるようになりました。`}<br parentName="p"></br>{`
`}{`そして、とうとう１５歳の時の夢が`}<br parentName="p"></br>{`
`}{`現実になりました。`}</p>
    <p>{`好きな音楽が仕事になる。`}<br parentName="p"></br>{`
`}{`毎日、ストレスも不安もない。`}<br parentName="p"></br>{`
`}{`自分のやりたいようにやれば、`}<br parentName="p"></br>{`
`}{`みんなが喜んでくれる。`}<br parentName="p"></br>{`
`}<strong parentName="p">{`こんなにワクワクする人生はありません`}</strong>{`。`}</p>
    <p>{`なぜ、MIXができるようになったのか？`}<br parentName="p"></br>{`
`}{`それは、`}<br parentName="p"></br>{`
`}{`高価なプラグインを買ったからでもなく、`}<br parentName="p"></br>{`
`}{`闇雲に努力したからでもなく、`}<br parentName="p"></br>{`
`}{`単純に`}<br parentName="p"></br>{`
`}{`「`}<strong parentName="p">{`効率の良いやり方を知ったから`}</strong>{`」`}<br parentName="p"></br>{`
`}{`でした。`}</p>
    <p>{`一体どういうことか？`}<br parentName="p"></br>{`
`}{`詳しくは、僕が「知識だけで」自由にMIXができるようになるまでの過程を、下の記事で公開しています。`}<br parentName="p"></br>{`
`}{`（MIXに関する無料メルマガもこちら↓）`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      