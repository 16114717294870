import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

import { Link } from "gatsby"
import SEO from '@components/seo'
import lazysizes from 'lazysizes'
import '@layouts/global.css'

import Header from '@components/header'
import Footer from '@components/footer'
import H2Back from '@components/h2-back'

import Rinen from "@components/rinen-he"
import KijiItiran from "@components/kiji-itiran"


export default ({ data: { mdx } }) => {

	// 理念ページの場合は、理念フッターを消す
	let nasi = ''

	if (mdx.frontmatter.key == 'rinen') {
		nasi = 'nasi'
	}
	else {
		nasi = ''
	}

	if (typeof document !== `undefined`) {

		// メルマガページから変遷した時に、背景を白に戻す機能
		document.body.classList.remove('haikei-kuro')
	}

	return (

		<MDXProvider
			components={{
				h2: props => <h2>{props.children}<H2Back/></h2>,
			}}
		>

			<SEO title={mdx.frontmatter.title} description={mdx.frontmatter.description} />
			<Header />
			<main className="b-center honbun seigen">

				<h1>{mdx.frontmatter.title}</h1>

				<div className='md'>
					<MDXRenderer>{mdx.body}</MDXRenderer>
					<Rinen tuika_class={nasi}/>
					<KijiItiran kagi={mdx.frontmatter.key}/>
				</div>

			</main>

			<Link to='/'><div class='top_back t-center'>トップページへ戻る</div></Link>
			<Footer />

		</MDXProvider>

	)
}

export const pageQuery = graphql`
  query ($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
			path
			date
			title
			description
			syurui
			key
      }
    }
  }
`